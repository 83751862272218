import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/DYCHLayout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-18-image-lightbox/style.css';
import {toast, ToastContainer} from "react-toastify";
import {CloseButton, Fade} from "./components/common/Toast";
//import {createMemoryHistory} from 'history';

const App = () => {
    //const history = createMemoryHistory();
  // {/* <Router location={history.location} navigator={history} basename={process.env.PUBLIC_URL}> */}
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default App;
