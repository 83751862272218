import React, { useEffect } from 'react';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
//import { Redirect } from "react-router-dom";
//import DashboardLayout from './DashboardLayout';
//import LoggedInLayout from './LoggedInLayout';
//import ErrorLayout from './ErrorLayout';
import { loggedInStatus } from '../data/appconfig/config';
import loadable from '@loadable/component';
import Cookies from '../scripts/js.cookie.mjs';
import {Routes} from "react-router-dom";
//const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Landing = loadable(() => import('../components/landing/Landing'));
const AboutLanding = loadable(() => import('../components/landing/AboutLanding'));
const SatsangLanding = loadable(() => import('../components/landing/SatsangLanding'));
const LoginLanding = loadable(() => import('../components/login/Landing'));
const LanguageLanding = loadable(() => import('../components/landing/LanguageLanding'));
const TeluguLanguageLanding = loadable(() => import('../components/landing/TeluguLanguageLanding'));
const RegistrationLanding = loadable(() => import('../components/registration/Landing'));
const BaladattaLanding = loadable(() => import('../components/landing/BaladattaLanding'));
const KidsyogaLanding = loadable(() => import('../components/landing/KidsyogaLanding'));
const CenterUpdatesLanding = loadable(() => import('../components/landing/CenterUpdatesLanding'));
const SriSwamijiLanding = loadable(() => import('../components/landing/SriSwamijiLanding'));
const EnrollLanding = loadable(() => import('../components/landing/EnrollLanding'));
const BhajansLanding = loadable(() => import('../components/landing/BhajansLanding'));
const CookiePolicy = loadable(() => import('../components/landing/CookiePolicy'));
const PrivacyPolicy = loadable(() => import('../components/landing/PrivacyPolicy'));
//const KumaraKriyaYogaLanding = loadable(() => import('../components/kumarakriyayoga/Landing'));
const BagavadGitaLanding = loadable(() => import('../components/landing/BagavadGitaLanding'));
const ComputerScienceLanding = loadable(() => import('../components/landing/ComputerScienceLanding'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));
// const MahaSivaRathriLanding = loadable(() => import('../components/landing/MahaSivaRathriLanding'));
//const TriHomaLanding = loadable(() => import('../components/landing/TriHomaLanding'));
const MahaDarshanaLanding = loadable(() => import('../components/landing/MahaDarshanaLanding'));
const HDYCVisitLanding = loadable(() => import('../components/landing/HDYCVisitLanding'));
const HotelsLanding = loadable(() => import('../components/landing/HotelsLanding'));
const HDYCPujasLanding = loadable(() => import('../components/landing/HDYCPujasLanding'));
const SMSTextLanding = loadable(() => import('../components/landing/SMSTextLanding'));
const BGParayanaLanding = loadable(() => import('../components/landing/BGParayanaLanding'));
const EventEnrollLanding = loadable(() => import('../components/landing/EventEnrollLanding'));
const DKYLanding = loadable(() => import('../components/landing/DKYLanding'));
/*
<meta property="og:url"                content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
<meta property="og:type"               content="article" />
<meta property="og:title"              content="When Great Minds Don’t Think Alike" />
<meta property="og:description"        content="How much does culture influence creative thinking?" />
<meta property="og:image"              content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />
*/
const Layout = () => {
  useEffect(() => {
    //AuthBasicLayout.preload();
    Landing.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
  }, []);

  const params = new URLSearchParams(window.location.search);
   
        const loggedIn = params.has("LoggedIn");
        const logout = params.has("Logout");
        if (loggedIn) {
          console.log('User Logged In ...');
            sessionStorage.setItem("loggedIn",btoa(loggedInStatus));
            if(params.has("referrer")) {
              window.location.href = window.location.origin + params.get("referrer");
            } else {
              window.location.href = window.location.origin + window.location.pathname;
            }
            
        }

        if (Cookies.get("loggedIn")) {
          sessionStorage.setItem("loggedIn",btoa(loggedInStatus));
        }

        if (logout) {
          sessionStorage.removeItem("loggedIn");
           window.location.href = window.location.origin + window.location.pathname;
           
      }


  return (
      <Routes>
        <Route path="/aboutus" element={<AboutLanding/>} />
        <Route path="/sriswamiji" element={<SriSwamijiLanding/>} />
        <Route path="/satsang" element={<SatsangLanding/>} />
        <Route path="/classes/baladatta/languages/telugu" element={<TeluguLanguageLanding/>} />
        <Route path="/classes/baladatta/languages" element={<LanguageLanding/>} />
        <Route path="/classes/baladatta/telugulanguage" element={<LanguageLanding/>} />
        <Route path="/login" element={<LoginLanding/>} />
        <Route path="/registration" element={<RegistrationLanding/>} />
        <Route path="/classes/baladatta/bhajans" element={<BhajansLanding/>} />
        <Route path="/classes/baladatta" element={<BaladattaLanding/>} />
        <Route path="/classes/baladatta/yogaforkids" element={<KidsyogaLanding/>} />
        <Route path="/CenterUpdates" element={<CenterUpdatesLanding/>} />
        {/*<Route path="/classes/baladatta/kumarakriyayoga" element={KumaraKriyaYogaLanding} />*/}
        <Route path="/classes/bhagavadgita" element={<BagavadGitaLanding/>} />
        <Route path="/classes/computerprogramming" element={<ComputerScienceLanding/>} />
        <Route path="/classes/dattakriyayoga" element={<DKYLanding/>} />
        <Route path="/classes/enroll" element={<EnrollLanding/>} />
        <Route path="/cookiepolicy" element={<CookiePolicy/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        {/* <Route path="/events/mahashivarathri" element={MahaSivaRathriLanding} /> */}
        <Route path="/events/mahadarshana" element={<MahaDarshanaLanding/>} />
        <Route path="/events/HDYC-Visit-July2023" element={<HDYCVisitLanding/>} />        
        <Route path="/HDYC/Hotels" element={<HotelsLanding/>} />
        <Route path="/HDYC/Pujas-Sponsorships" element={<HDYCPujasLanding/>} />
        <Route path="/sandheshalu" element={<SMSTextLanding/>} />
        <Route path="/events/bgparayana" element={<BGParayanaLanding/>} />
        {/*<Route path="/events/homa" element={TriHomaLanding} />*/}
        <Route path="/events/register" element={<EventEnrollLanding/>} />
        <Route path="/" element={<Landing/>} />
      </Routes>
  );
};

export default Layout;
